import React from "react";
import styles from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faXTwitter
} from "@fortawesome/free-brands-svg-icons";

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={`${styles.backgroundWave} ${styles.mobileSpacing}`}>
        <img src="/onda-background-footer.svg" alt="Wave background" />
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerContent}>
          <div className={styles.column}>
            <h3>
              <img
                src="/kenettologo.png"
                alt="Kenetto Logo"
                className={styles.logo}
              />
            </h3>
            <p className={styles.logoText}>
              Promover o acesso a uma vida saudável, orientando escolhas que
              previnam a doença e ilimitem o sofrimento, criando bem-estar.
            </p>
          </div>

          <div className={styles.column}>
            <h3>Contactos</h3>
            <a href="mailto:contactus@kenetto.com" className={styles.emailLink}>
              contactus@kenetto.com
            </a>
          </div>

          <div className={styles.column}>
            <h3>Links Úteis</h3>
            <ul className={styles.linkList}>
              <li>
                <a href="https://www.kenetto.com">Kenetto</a>
              </li>
              <li>
                <a href="https://myscribo.com/">Scribo</a>
              </li>
              <li>
                <a href="https://myscribo.com/terms/">Termos e Condições</a>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h3>Social Media</h3>
            <div className={styles.socialIcons}>
              <a
                href="https://www.facebook.com/kenettoOrg"
                className={styles.socialIcon}
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.instagram.com/kenetto_org/"
                className={styles.socialIcon}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://x.com/kenetto_org" className={styles.socialIcon}>
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
              <a
                href="https://pt.linkedin.com/company/kenetto"
                className={styles.socialIcon}
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
